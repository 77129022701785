.whxuudv{margin-top:16px;padding:8px 20px;}

.fobetyl{margin-bottom:8px;color:#000;font-weight:600;font-size:16px;line-height:24px;}
.i1alquu0{width:24px;height:24px;margin-left:10px;color:#a3a5ba;}
.czv0vah{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin-top:20px;padding:20px 0 0;}.czv0vah.isShowConfirmAddressSwitch{border-top:1px solid #f6f6f8;}
.c10etkt6{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-box-flex:1;-webkit-flex-grow:1;-ms-flex-positive:1;flex-grow:1;}
.c7w6uby{color:#a3a5ba;font-weight:600;font-size:14px;}
.c108c3ep{font-weight:600;font-size:16px;}
.h1232ek5{padding:12px 0;color:#a3a5ba;font-size:14px;line-height:21px;text-align:center;border-top:1px solid rgba(0,0,0,0.05);}
.e1ezqv76{margin-left:66px;color:#f43d3d;font-weight:600;font-size:16px;}
.trflgph{margin-bottom:8px;}.trflgph.isFree{color:#2db533;}
.tz0zqq4{margin-bottom:8px;}
.n1tgfced{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin-bottom:8px;padding:12px 20px;border:1px solid #f6f6f8;border-radius:12px;}
.n1s3zavy{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-flex:1;-webkit-flex-grow:1;-ms-flex-positive:1;flex-grow:1;font-weight:600;font-size:16px;}
.ss1otai{width:24px;height:24px;margin-right:12px;}
