.w1u0glhy{position:relative;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;margin-bottom:-1px;padding:16px 20px;border:1px solid #f6f6f8;border-radius:12px;}
.t145743d{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;}
.few6do9{color:#000;font-weight:600;font-size:16px;line-height:24px;}
.a1vy5vjs{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;color:#5887ff;cursor:pointer;}.a1vy5vjs.disabled{cursor:auto;pointer-events:none;}.a1vy5vjs.error{color:#f43d3d;}
.m1yl73p1{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;margin-top:8px;}
.wxjazwt{width:24px;height:24px;color:#a3a5ba;}
.w1w5l8s2{width:20px;height:20px;margin-right:5px;}
.tc9ifva{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;width:44px;height:44px;background:#f6f6f8;border-radius:12px;}.tc9ifva.isOpen{background:#5887ff;}.tc9ifva.isOpen .wxjazwt{color:#fff;}
.i1vtno5b{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex:1;-ms-flex:1;flex:1;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;min-width:0;margin-left:12px;}
.s1dbpbdz{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;height:44px;}
.t1xr0yyx{max-width:200px;overflow:hidden;color:#000;font-weight:600;font-size:24px;line-height:140%;white-space:nowrap;text-overflow:ellipsis;}
.e1ba9gnn{color:#a3a5ba;}
.c1mozzwv{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin-left:26px;}
.c47fjt1{width:24px;height:24px;color:#000;}
.t1gus5ch{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;min-width:0;cursor:pointer;}.t1gus5ch.isOpen .t1xr0yyx,.t1gus5ch.isOpen .c47fjt1{color:#5887ff;}
.a1fbshi4{max-width:200px;color:#000;font-weight:600;font-size:28px;line-height:120%;text-align:right;background:transparent;border:0;outline:none;-webkit-appearance:none;-moz-appearance:none;appearance:none;}.a1fbshi4::-webkit-input-placeholder{color:#a3a5ba;}.a1fbshi4::-moz-placeholder{color:#a3a5ba;}.a1fbshi4:-ms-input-placeholder{color:#a3a5ba;}.a1fbshi4::placeholder{color:#a3a5ba;}.a1fbshi4.error{color:#f43d3d;}
.b1il3o3u{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:end;-webkit-justify-content:flex-end;-ms-flex-pack:end;justify-content:flex-end;height:24px;}
.bomt52x{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;color:#a3a5ba;font-weight:600;font-size:16px;line-height:24px;}
.a337d1n{margin-left:3px;}
.dvba4dv{position:absolute;right:0;left:0;z-index:2;margin-top:8px;overflow:hidden;background:#fff;border-radius:12px;box-shadow:0 10px 32px rgba(0,0,0,0.1);}
.d1q6yf8o{padding:0 20px;border-radius:0 0 12px 12px;box-shadow:none;-webkit-backdrop-filter:blur(15px);backdrop-filter:blur(15px);}
.t1ssva9{padding:12px 0;color:#a3a5ba;font-weight:600;font-size:14px;line-height:140%;}
.f1888xml{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex:1;-ms-flex:1;flex:1;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;height:66px;}.f1888xml > :not(:last-child){margin-right:12px;}
.d1qpwt4s{max-height:400px;padding-bottom:14px;overflow-y:auto;}
.tf3wj53{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin:0 20px;color:#a3a5ba;font-weight:600;font-size:14px;line-height:140%;}
.ys4reri.tf3wj53{height:32px;}
.a1sr0me.tf3wj53{height:44px;}
