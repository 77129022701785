.www8bqi{position:relative;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;height:1389px;margin-top:187px;}@media (max-width:568px){.www8bqi::after{position:absolute;top:-100px;left:50%;width:30px;height:30px;margin-left:-15px;background:url('../../../../../../../../components/pages/landing/Functions/arrow-down-mobile.png') no-repeat 50%;background-size:30px;content:'';}}@media (min-width:568px){.www8bqi{height:1348px;margin-top:130px;}}@media (min-width:1024px){.www8bqi{height:1177px;margin-top:119px;}}@media (min-width:1440px){.www8bqi{height:854px;margin-top:110px;}}
.ha876sr{position:absolute;z-index:1;}@media (min-width:1024px){.ha876sr{top:-40px;right:-161px;width:305px;height:159px;}.ha876sr::before{position:absolute;top:0;left:0;width:128px;height:128px;background:url('../../../../../../../../components/pages/landing/Functions/hackathon-second.svg') no-repeat 50% 50%;content:'';}.ha876sr::after{position:absolute;right:0;bottom:0;width:128px;height:128px;background:url('../../../../../../../../components/pages/landing/Functions/hackathon-third.svg') no-repeat 50% 50%;content:'';}}@media (min-width:1440px){.ha876sr{top:-110px;right:-48px;width:300px;height:150px;}.ha876sr::before{top:initial;bottom:0;left:0;}.ha876sr::after{top:0;right:0;bottom:initial;}}
.c13xwuk3{position:relative;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;max-width:1178px;}.c13xwuk3::before{position:absolute;top:-291px;width:1896px;height:2027px;background:url('../../../../../../../../components/pages/landing/Functions/gradient.png') no-repeat 50%;background-size:1896px 2027px;content:'';}.c13xwuk3::after{position:absolute;top:0;width:926px;height:1389px;background:url('../../../../../../../../components/pages/landing/Functions/ellipse-functions.svg') no-repeat 50%;background-size:926px 1389px;content:'';}@media (min-width:568px){.c13xwuk3::before{width:1798px;height:1923px;background-size:1798px 1923px;}.c13xwuk3::after{width:1250px;height:1348px;background-size:1250px 1348px;}}@media (min-width:1024px){.c13xwuk3::before{top:-259px;width:1674px;height:1791px;background-size:1674px 1791px;}.c13xwuk3::after{width:1250px;height:1177px;background-size:1250px 1177px;}}@media (min-width:1440px){.c13xwuk3{-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;}.c13xwuk3::before{top:-257px;display:none;width:1506px;height:1311px;background-size:1506px 1311px;}.c13xwuk3::after{display:none;width:1458px;height:854px;background-size:1458px 854px;}}
.cp9ve1b{position:absolute;bottom:-151px;left:-1021px;width:3204px;height:1647px;background:url('../../../../../../../../components/pages/landing/Functions/curves-functions.svg') no-repeat 50%;background-size:3204px 1647px;content:'';}@media (min-width:568px){.cp9ve1b{bottom:-78px;left:-685px;width:2959px;height:1521px;background-size:2959px 1521px;}}@media (min-width:1024px){.cp9ve1b{bottom:18px;left:-295px;width:2759px;height:1418px;background-size:2759px 1418px;}}@media (min-width:1440px){.cp9ve1b{bottom:-363px;left:-144px;width:2759px;height:1418px;background-size:2759px 1418px;}}
.cfhmpmi{position:relative;z-index:1;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:end;-webkit-justify-content:flex-end;-ms-flex-pack:end;justify-content:flex-end;height:854px;}@media (min-width:1024px){.cfhmpmi{-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;height:545px;}}@media (min-width:1440px){.cfhmpmi{padding-right:94px;}.cfhmpmi::before{position:absolute;right:-298px;width:1506px;height:1311px;background:url('../../../../../../../../components/pages/landing/Functions/gradient.png') no-repeat 50%;background-size:1506px 1311px;content:'';}.cfhmpmi::after{position:absolute;right:0;width:1458px;height:854px;background:url('../../../../../../../../components/pages/landing/Functions/ellipse-functions.svg') no-repeat 50%;background-size:1458px 854px;content:'';}}@media (min-width:1440px){.cfhmpmi::after{background-position:100% 50%;background-size:854px 854px;}}
.cb0bkr5{position:relative;z-index:1;width:286px;height:504px;}
.shc276u{z-index:1;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;margin-top:71px;}@media (min-width:568px){.shc276u{margin-top:90px;}}@media (min-width:1024px){.shc276u{-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;margin-top:0;margin-left:100px;}}
.i38oieq{width:48px;height:48px;background-color:rgba(255,255,255,0.05);background-repeat:no-repeat;background-position:50%;border-radius:18px;}.i38oieq.send{background-image:url('../../../../../../../../components/pages/landing/Functions/selector-send.svg');background-size:16px 13px;}.i38oieq.store{background-image:url('../../../../../../../../components/pages/landing/Functions/selector-store.svg');background-size:16px 16px;}.i38oieq.swap{background-image:url('../../../../../../../../components/pages/landing/Functions/selector-swap.svg');background-size:28px 28px;}
.s1ysa3t9{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;padding:20px;cursor:pointer;}.s1ysa3t9.active,.s1ysa3t9:hover{background:url('../../../../../../../../components/pages/landing/Functions/selector-bg.svg') no-repeat 50% 50%;background-size:contain;}.s1ysa3t9.active .i38oieq,.s1ysa3t9:hover .i38oieq{background-color:#fff;}.s1ysa3t9:not(:last-child){margin-right:20px;}@media (min-width:568px){.s1ysa3t9:not(:last-child){margin-right:32px;}}@media (min-width:1024px){.s1ysa3t9:not(:last-child){margin-right:0;margin-bottom:20px;}}
.s1n5c9uz{margin-top:12px;color:#fff;font-weight:bold;font-size:16px;font-family:'Aktiv Grotesk Corp',sans-serif;line-height:140%;white-space:nowrap;text-align:center;}
.s3ogml1{position:absolute;top:0;right:0;background-repeat:no-repeat;background-position:center;-webkit-transition:top 0.2s,bottom 0.2s,right 0.2s,left 0.2s;transition:top 0.2s,bottom 0.2s,right 0.2s,left 0.2s;}.s3ogml1:first-child{top:0;right:0;z-index:1;width:232px;height:502px;background-size:232px 502px;-webkit-filter:drop-shadow(0 32px 56px rgba(0,0,0,0.5));filter:drop-shadow(0 32px 56px rgba(0,0,0,0.5));}.s3ogml1:nth-child(2){top:initial;bottom:0;left:0;width:208px;height:452px;background-size:208px 452px;-webkit-transform:rotate(-10deg);-ms-transform:rotate(-10deg);transform:rotate(-10deg);}.s3ogml1.send{background-image:url('../../../../../../../../components/pages/landing/Functions/screen-1-desktop.png');}.s3ogml1.store{background-image:url('../../../../../../../../components/pages/landing/Functions/screen-2-desktop.png');}.s3ogml1.swap{background-image:url('../../../../../../../../components/pages/landing/Functions/screen-3-desktop.png');}
.t1txmbhl{z-index:1;margin-top:71px;padding:0 40px;}@media (min-width:568px){.t1txmbhl{margin-top:80px;}}@media (min-width:1024px){.t1txmbhl{margin-top:100px;}}@media (min-width:1440px){.t1txmbhl{min-height:419px;margin-top:0;margin-left:88px;padding:0;}}
.t1dc9t51{max-width:456px;}
.tzk9e0d{color:#f9f9f9;font-size:24px;font-family:'Aktiv Grotesk Corp',sans-serif;line-height:32px;}@media (min-width:1440px){.tzk9e0d{font-size:32px;line-height:48px;}}
.tvfiwce{font-weight:900;font-family:'GT Super Ds Trial',sans-serif;}
.d1p712hd{margin-top:32px;color:#f9f9f9;font-size:20px;font-family:'Aktiv Grotesk Corp',sans-serif;line-height:30px;opacity:0.3;}@media (min-width:1440px){.d1p712hd{font-size:24px;line-height:36px;}}
