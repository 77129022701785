.w1xwrcq2{position:relative;height:64px;}
.fu4syn9{position:fixed;z-index:1;width:100%;height:64px;background:#fff;border-bottom:1px solid #f6f6f8;box-shadow:0 1px 8px rgba(0,0,0,0.05);}
.s1ekikc4{height:100%;padding:0 20px;}
.m17m3d8c{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex:1;-ms-flex:1;flex:1;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;width:100%;max-width:796px;height:100%;margin:0 auto;}
.c1gyjr0u{position:relative;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;width:100%;height:100%;}
.l1r08nhz{color:#000;font-weight:bold;font-size:22px;line-height:120%;-webkit-text-decoration:none;text-decoration:none;}
.l1pmujzi{width:108px;height:38px;}
.b1hsvjbd{position:absolute;left:272px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;color:#a3a5ba;font-weight:600;font-size:20px;line-height:120%;}
.b1u14hfi{width:22px;height:22px;margin-left:-2px;color:#a3a5ba;-webkit-transform:rotate(90deg);-ms-transform:rotate(90deg);transform:rotate(90deg);}
.buqkb97{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;width:32px;height:32px;margin-right:16px;background:#f6f6f8;border-radius:12px;cursor:pointer;}.buqkb97:hover{background:#eff3ff;}.buqkb97:hover .b1u14hfi{color:#5887ff;}
