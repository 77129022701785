
.thvx7gr{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.t1efrr2r{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin-right:20px;padding:8px;background-color:#5887ff;border-radius:12px;}
.tdap0np{width:20px;height:20px;color:#fff;}
.s7uyxyz{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;padding:16px 20px 0;}
.sk087qb{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;padding:20px 0;}.sk087qb:not(:last-child){border-bottom:1px solid rgba(0,0,0,0.05);}
.bequ2pb{width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;padding:0;}.bequ2pb:disabled{color:#a3a5ba;background:#fff !important;}
.i1uc39ez{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;width:36px;height:36px;margin-right:20px;padding:8px;background:#f6f6f8;border-radius:12px;}
.strqrhe{width:20px;height:20px;color:#a3a5ba;}
.t1t39fdz{-webkit-box-flex:1;-webkit-flex-grow:1;-ms-flex-positive:1;flex-grow:1;font-weight:600;font-size:16px;}
.wu12hle{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.w1wzvkci{margin-left:10px;width:18px;height:18px;color:#ffa631;}
