
.hthrull{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.ta8g7pn{max-width:230px;margin-left:16px;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;}
.t35blbr{color:#000;font-weight:600;font-size:20px;line-height:100%;}
.t1vsnxod{margin-top:4px;color:#a3a5ba;font-weight:600;font-size:14px;line-height:140%;}
.b1mc833u{display:grid;grid-auto-flow:column;grid-gap:16px;}
.b18nuck{width:36px;padding:0;box-shadow:0 4px 12px rgba(88,135,255,0.25);}
.pv2mihe{width:20px;height:20px;color:#fff;}
.p18bvaz6{padding:16px 20px;}.p18bvaz6.isSticky{padding:0 16px;}
.vfwpt52{color:#000;font-weight:bold;font-size:22px;line-height:120%;}.vfwpt52.isSticky{font-weight:bold;font-size:16px;line-height:120%;}
.bnqava7{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;color:#a3a5ba;font-weight:600;font-size:14px;line-height:21px;}.bnqava7:not(:first-child){margin-top:4px;}.bnqava7.isSticky{margin-top:0;font-weight:600;font-size:14px;line-height:120%;}

.v152vpg2{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;}
.w1gyex9g{position:fixed;top:64px;z-index:2;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;width:524px;height:72px;padding:0 20px;background:#fff;border-radius:0 0 12px 12px;box-shadow:0 8px 16px rgba(0,0,0,0.1);}
.f1g6gzuh{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.tf692a3{margin-left:10px;}
.t6sg0fr{width:36px;padding:0;}
.tse0h6q{width:20px;height:20px;color:#a3a5ba;}
