.wky18cu{position:relative;padding:10px 0;}.wky18cu:not(:last-child)::after{position:absolute;right:10px;bottom:0;left:10px;border-bottom:1px solid rgba(0,0,0,0.05);content:'';}
.blsfsf4{width:48px;height:48px;margin-right:12px;}
.ta6ycbu.blsfsf4{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;background:#f6f6f8;border-radius:12px;}
.t1fiy8gg{width:25px;height:25px;color:#a3a5ba;}
.s1bn1dm0.blsfsf4{position:relative;}.s1bn1dm0.blsfsf4 > :nth-child(1){position:absolute;top:0;left:0;}.s1bn1dm0.blsfsf4 > :nth-child(2){position:absolute;right:0;bottom:0;}
.c16emgrj{-webkit-flex:1;-ms-flex:1;flex:1;}
.tyhaac0{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;color:#000;font-weight:600;font-size:16px;line-height:24px;}
.tibl6gz{text-transform:capitalize;}
.r1tnwv46{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.ah24c3d.isReceiver{color:#2db533;}
.s18lfdfk{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;}
.w8tf3jq{width:16px;height:16px;margin-left:9px;color:#f43d3d;}
.c149ztxt{width:15px;height:15px;margin-left:9px;color:#ffa631;}
.m1rfzcck{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;padding:10px;cursor:pointer;}.m1rfzcck:hover{background:#f6f6f8;border-radius:12px;}.m1rfzcck:hover .ta6ycbu{background:#fff;}.m1rfzcck:hover .tibl6gz{color:#5887ff;}
.b1gap204{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;margin-top:5px;color:#a3a5ba;font-weight:600;font-size:14px;line-height:16px;}
.l1jxdw3e{color:#a3a5ba;-webkit-text-decoration:none;text-decoration:none;}
