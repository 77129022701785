.w1u9xamq{display:grid;grid-gap:24px;}
.i1d04pnf{margin-top:10px;padding:20px;border-bottom:1px solid #f6f6f8;}
.i1yi39cu{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;padding:20px;border:1px solid #f6f6f8;border-radius:12px;}.i1yi39cu:not(:last-child){margin-bottom:8px;}
.i1r2958w{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-flex:1;-webkit-flex-grow:1;-ms-flex-positive:1;flex-grow:1;font-size:16px;font-weight:600;}
.i63ycw2{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.lzoqg8f{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;padding:20px;}
.l1ce0lgx{padding:0 10px;font-size:16px;font-weight:600;color:#f43d3d;cursor:pointer;}

.smb4hgp{padding-left:3px;color:#a3a5ba;}
.cax6kc0{text-transform:capitalize;}
.tmzlhsa{color:#a3a5ba;font-weight:600;font-size:16px;text-align:right;}.tmzlhsa.overflow-ellipsis{width:250px;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;}
.cjv3a2s{width:20px;height:20px;color:#a3a5ba;}
.c1auxnum{margin-left:20px;-webkit-transform:rotate(270deg);-ms-transform:rotate(270deg);transform:rotate(270deg);}
.a50w6n{margin-bottom:8px;}
.a1o9f30g{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;width:95%;}

.a1vlgqdk.warning{color:#f43d3d;}
.mt7m1zb{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;width:263px;padding:5px 0;}
.t1yc92tf{margin-bottom:20px;}
