
.m1v5i9nx{padding:0 20px 170px;}
.c1y2mbv4{width:100%;max-width:796px;min-height:calc(100vh - 16px - 64px);margin:0 auto;padding-top:16px;}

.ckwynsp{display:grid;grid-auto-flow:column;grid-gap:20px;}
.c1fx7mj2{width:252px;height:-webkit-fit-content;height:-moz-fit-content;height:fit-content;}
.cs1ufhs{display:grid;grid-gap:16px;grid-template-rows:min-content;}
.couc7g2{width:524px;height:-webkit-fit-content;height:-moz-fit-content;height:fit-content;}
.cxapvfn{display:grid;grid-gap:24px;grid-template-rows:min-content;}
.cryz1qe{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
