
.f128gmbo{position:relative;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;margin-bottom:8px;padding:16px 20px;border:1px solid #f6f6f8;border-radius:12px;}
.fi6byu3{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;height:44px;margin-top:10px;padding:0 20px;color:#a3a5ba;font-weight:600;font-size:16px;line-height:24px;background:#f6f6f8;border-radius:12px;}
.f1h03qs8{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-flex:1;-webkit-flex-grow:1;-ms-flex-positive:1;flex-grow:1;}
.f1rxi6hx{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.b1idtknr{padding:24px 0;}.b1idtknr:not(:has(div:only-child)){padding:20px;}

.t1r00r18{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;font-size:14px;}
.tq7116i{-webkit-box-flex:1;-webkit-flex-grow:1;-ms-flex-positive:1;flex-grow:1;margin-right:5px;font-weight:normal;}
.tdkq6me{font-weight:600;}
