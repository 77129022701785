.w1n1sk0j{-webkit-flex:1;-ms-flex:1;flex:1;padding:0;}
.hudhabv{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;height:64px;padding:0 8px 0 20px;}
.toqmjtd{-webkit-flex:1;-ms-flex:1;flex:1;margin-right:7px;}
.c1iwn7im{color:#2db533;font-weight:600;font-size:14px;line-height:21px;}
.t1s1keia{color:#000;font-weight:600;font-size:14px;line-height:21px;}
.tml8uj3{color:#a3a5ba;font-weight:600;font-size:16px;line-height:24px;cursor:pointer;}
.eps8y49{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;width:48px;height:48px;border-radius:12px;cursor:pointer;}.eps8y49:hover{background:#f6f6f8;}
.e1aiw0c2{width:32px;height:32px;color:#5887ff;}
.c9uvqv1{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;border-top:1px solid rgba(0,0,0,0.05);}
.t2445qe{margin-top:20px;color:#202020;font-weight:600;font-size:16px;line-height:24px;}
.q1dj82yb{position:relative;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;margin:20px 0;padding:17px;border-radius:12px;}.q1dj82yb.isImageCopyAvailable:hover{background:#f6f6f8;cursor:pointer;}
.q1f5z4z4{position:absolute;right:0;bottom:10px;left:0;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;}
.q1coayyc{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;height:29px;padding:0 11px;color:#5887ff;font-weight:600;font-size:14px;line-height:21px;background:#fff;border-radius:12px;box-shadow:0 4px 16px rgba(0,0,0,0.1);}
.d1hn10hu{border-top:1px solid rgba(0,0,0,0.05);}
.d11pl600{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;padding:20px;}.d11pl600:not(:last-child){border-bottom:1px solid rgba(0,0,0,0.05);}

.f14hkg9p{color:#000;font-weight:600;font-size:14px;line-height:16px;}
.fqbjg3y{margin-top:4px;color:#a3a5ba;font-weight:600;font-size:13px;line-height:16px;cursor:pointer;}.fqbjg3y:hover{color:#5887ff;}
.s1ypkspb{width:24px;height:24px;color:#a3a5ba;}
.shdy84v{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-shrink:0;-ms-flex-negative:0;flex-shrink:0;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;width:36px;height:36px;margin-left:20px;background:rgba(163,165,186,0.1);border-radius:8px;cursor:pointer;}.shdy84v:hover{background:#eff3ff;}.shdy84v:hover .s1ypkspb{color:#5887ff;}
.fdquywb{padding:20px;color:#a3a5ba;font-size:14px;line-height:21px;text-align:center;border-top:1px solid rgba(0,0,0,0.05);}
