

.s1lz06r8{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;width:100%;height:54px;padding:0 16px 0 24px;color:#161616;font-weight:500;font-size:16px;font-family:'Aktiv Grotesk Corp',sans-serif;line-height:18px;background:#fff;border:1px solid rgba(22,22,22,0.15);border-radius:32px;outline:none;cursor:pointer;-webkit-appearance:none;-moz-appearance:none;appearance:none;}.s1lz06r8:not(:last-child){margin-bottom:12px;}
.w1tzyf2f{width:30px;height:30px;margin-right:16px;}.w1tzyf2f.sollet{background:url('../../../../../../../../../../components/pages/home/Auth/Login/Main/sollet.svg') no-repeat 50%;}.w1tzyf2f.phantom{background:url('../../../../../../../../../../components/pages/home/Auth/Login/Main/phantom.png') no-repeat 50%;}
.awzp5s2{-webkit-flex:1;-ms-flex:1;flex:1;width:20px;height:20px;background:url('../../../../../../../../../../components/pages/home/Auth/Login/Main/arrow-right.svg') no-repeat 100% 50%;opacity:0.15;}
.d1xdl1kr{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;height:22px;margin:32px 0;}.d1xdl1kr::before{position:absolute;width:100%;height:1px;background:#161616;opacity:0.15;content:'';}
.d122f5yz{z-index:1;padding:0 10px;color:#161616;font-weight:500;font-size:14px;font-family:'Aktiv Grotesk Corp',sans-serif;line-height:20px;background:#fff;}
.s1g2unp1{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;margin-bottom:32px;}
.s1nnhx90{margin-bottom:8px;color:#161616;font-weight:500;font-size:16px;font-family:'Aktiv Grotesk Corp',sans-serif;line-height:24px;}
.m195jra4{min-height:92px;padding:15px;color:#161616;font-size:16px;font-family:'Aktiv Grotesk Corp',sans-serif;line-height:22px;background:#f6f6f8;border:1px solid transparent;border-radius:12px;}.m195jra4.hasError{border-color:#f43d3d;}.m195jra4::-webkit-input-placeholder{color:#1616164c;}.m195jra4::-moz-placeholder{color:#1616164c;}.m195jra4:-ms-input-placeholder{color:#1616164c;}.m195jra4::placeholder{color:#1616164c;}
