.wjwkukk{position:relative;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;background-image:url('../../../../../../../../components/pages/landing/Footer/ellipse.svg');background-repeat:no-repeat;background-position:50% 0;background-size:101% 100%;}
.fxoi5c0{position:relative;z-index:1;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex:1;-ms-flex:1;flex:1;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.tgy0855{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;padding:56px 40px 80px;}@media (min-width:568px){.tgy0855{padding:80px 40px;}}@media (min-width:1024px){.tgy0855{padding:80px 40px 72px;}}@media (min-width:1440px){.tgy0855{padding:55px 40px 80px;}}
.lexipak{width:64px;height:64px;background:url('../../../../../../../../assets/images/big-logo.png') no-repeat 50% 50%;background-size:64px 64px;}
.t1gegh9m{margin-top:56px;color:#f9f9f9;font-weight:900;font-size:32px;font-family:'GT Super Ds Trial',sans-serif;line-height:36px;text-align:center;}@media (min-width:568px){.t1gegh9m{max-width:488px;margin-top:80px;font-size:44px;line-height:56px;}}@media (min-width:1024px){.t1gegh9m{max-width:886px;margin-top:64px;font-size:48px;line-height:56px;}}@media (min-width:1440px){.t1gegh9m{max-width:1067px;margin-top:56px;}}
.b1fdtsta{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;margin-top:56px;}.b1fdtsta > :not(:last-child){margin-bottom:20px;}@media (min-width:568px){.b1fdtsta{margin-top:80px;}.b1fdtsta > *{width:388px;}}@media (min-width:1024px){.b1fdtsta{-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;margin-top:64px;}.b1fdtsta > *{width:inherit;margin-bottom:0 !important;}.b1fdtsta > :not(:last-child){margin-right:20px;}}
.b198v99l{width:100%;background:#161616;}
.d1yxnsel{width:100%;height:1px;background:#2d2d2d;}
.m1qvpzjf{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;width:100%;}
.m1hv0lyw{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;padding:32px 50px;}@media (min-width:568px){.m1hv0lyw{padding:56px 103px 78px;}}@media (min-width:1024px){.m1hv0lyw{-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;-webkit-align-items:initial;-webkit-box-align:initial;-ms-flex-align:initial;align-items:initial;padding:80px 88px;}}@media (min-width:1440px){.m1hv0lyw{max-width:1442px;padding:68px 47px;}}
.la8hbzc{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;height:34px;}
.lboftwg{width:80px;height:18px;background:url('../../../../../../../../components/pages/landing/Footer/logo.png') no-repeat 50% 50%;background-size:80px 18px;}
.dc2bw81{width:1px;height:34px;margin:0 24px 0 31px;background:rgba(255,255,255,0.2);}
.w1bv1z9l{color:#fff;font-weight:bold;font-size:24px;font-family:'GT Super Txt Trial',sans-serif;line-height:140%;}
.brwmyic{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;width:100%;padding:32px 40px;color:rgba(255,255,255,0.3);font-weight:500;font-family:'Aktiv Grotesk Corp',sans-serif;}@media (min-width:568px){.brwmyic{padding:25px 40px;font-size:20px;line-height:140%;}}@media (min-width:1024px){.brwmyic{padding:20px 40px;font-size:16px;line-height:140%;}}@media (min-width:1440px){.brwmyic{padding:15px 40px;}}
