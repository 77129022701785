.wt6eeca{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;background-image:url('../../../../../../../../components/pages/landing/YouCan/bg-youcan.svg');background-repeat:no-repeat;background-position:50% 0;background-size:101% 25%;}
.t3z4fne{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;padding:100px 0 96px;}@media (min-width:568px){.t3z4fne{padding:100px 0 110px;}}@media (min-width:1024px){.t3z4fne{padding:120px 0 70px;}}@media (min-width:1440px){.t3z4fne{padding:168px 0 90px;}}
.tc29w1o{max-width:295px;color:#161616;font-weight:900;font-size:40px;font-family:'GT Super Ds Trial',sans-serif;line-height:50px;text-align:center;}@media (min-width:568px){.tc29w1o{max-width:488px;font-size:48px;line-height:56px;}}@media (min-width:1024px){.tc29w1o{max-width:864px;font-size:48px;line-height:56px;}}@media (min-width:1440px){.tc29w1o{max-width:1068px;font-size:60px;line-height:64px;}}
.h7gs700{position:relative;max-width:296px;margin-top:95px;color:#161616;font-size:20px;font-family:'Aktiv Grotesk Corp',sans-serif;line-height:24px;text-align:center;}.h7gs700::after{position:absolute;top:-55px;left:-30px;width:352px;height:170px;background:url('../../../../../../../../components/pages/landing/YouCan/circle.svg') no-repeat 50%;background-size:352px 170px;content:'';}@media (min-width:568px){.h7gs700{max-width:initial;margin-top:101px;line-height:44px;}.h7gs700::after{top:-73px;left:-60px;width:516px;height:184px;background-size:516px 184px;}}@media (min-width:1024px){.h7gs700{margin-top:105px;font-size:20px;line-height:44px;}.h7gs700::after{width:516px;height:184px;background-size:516px 184px;}}@media (min-width:1440px){.h7gs700{margin-top:120px;font-size:24px;line-height:44px;}.h7gs700::after{top:-98px;left:-50px;width:592px;height:211px;background-size:592px 211px;}}
.t1bubuuq{font-weight:700;}
.mtrsejv{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;background:#f9f9f9;}
.m142syyj{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;width:100%;padding:0 40px;}@media (min-width:568px){.m142syyj{padding:0 56px;}}@media (min-width:1024px){.m142syyj{-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;max-width:864px;padding:0;}}@media (min-width:1440px){.m142syyj{max-width:1204px;}}
.t1ikle8j{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;width:100%;max-width:456px;}@media (min-width:568px){.t1ikle8j{-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}}
@media (min-width:568px){.t10meg3w{max-width:456px;}}@media (min-width:1440px){.t10meg3w{max-width:450px;}}
.t3drztl{color:#161616;font-size:40px;font-family:'GT Super Ds Trial',sans-serif;line-height:40px;}@media (min-width:568px){.t3drztl{font-size:36px;}}@media (min-width:1440px){.t3drztl{font-size:48px;line-height:50px;}}
.t18hdryu{font-weight:900;}
.t1c9xbsr{margin-top:48px;color:#161616;font-size:18px;font-family:'Aktiv Grotesk Corp',sans-serif;line-height:32px;}@media (min-width:568px){.t1c9xbsr{font-size:24px;line-height:32px;}}@media (min-width:1024px){.t1c9xbsr{font-size:20px;line-height:30px;}}@media (min-width:1440px){.t1c9xbsr{margin-top:50px;font-size:24px;line-height:34px;}}
.txoxrgt{color:#50d5ff;font-weight:900;}
.wcx8kkh{position:relative;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;width:100%;height:453px;margin-top:121px;}.wcx8kkh::before{position:absolute;width:802px;height:453px;background-image:url('../../../../../../../../components/pages/landing/YouCan/ellipse-youcan.svg');background-repeat:no-repeat;background-position:100% 50%;background-size:802px 453px;content:'';}.wcx8kkh::after{position:absolute;left:65px;width:526px;height:431px;background-image:url('../../../../../../../../components/pages/landing/YouCan/window-youcan.svg');background-repeat:no-repeat;background-position:100% 50%;background-size:552px 431px;-webkit-filter:drop-shadow(-34px 42px 100px rgba(0,0,0,0.15));filter:drop-shadow(-34px 42px 100px rgba(0,0,0,0.15));content:'';}@media (min-width:568px){.wcx8kkh{height:668px;}.wcx8kkh::before{width:1183px;height:668px;background-size:1183px 668px;}.wcx8kkh::after{left:30px;width:913px;height:714px;background-size:913px 714px;}}@media (min-width:1024px){.wcx8kkh{-webkit-flex:1;-ms-flex:1;flex:1;margin-top:0;margin-left:34px;}}@media (min-width:1440px){.wcx8kkh{-webkit-flex:initial;-ms-flex:initial;flex:initial;width:846px;height:873px;margin-left:20px;}.wcx8kkh::before{width:1426px;height:873px;background-size:1426px 873px;}.wcx8kkh::after{left:90px;width:796px;height:836px;background-size:796px 836px;}}
.bv4a8s4{background:#f9f9f9;}
