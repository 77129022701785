.t1n1hkij.tyyw6dd{-webkit-align-items:flex-start;-webkit-box-align:flex-start;-ms-flex-align:flex-start;align-items:flex-start;position:relative;color:#000;background-color:#fff;}
.t1xsfu32{padding:10px;border-radius:12px;}
.thfo02f{-webkit-flex-shrink:0;-ms-flex-negative:0;flex-shrink:0;width:22px;height:22px;}
.injglp9{-webkit-flex-shrink:0;-ms-flex-negative:0;flex-shrink:0;width:24px;height:22px;}
.t19lpd0f{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;padding:0 10px 0 16px;}
.tly5grb{display:inline-block;font-weight:600;font-size:16px;}.tly5grb::first-letter{text-transform:uppercase;}
.t1ityucl{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-flex:1;-webkit-flex-grow:1;-ms-flex-positive:1;flex-grow:1;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;padding-top:5px;color:#a3a5ba;font-weight:600;font-size:16px;}.t1ityucl.confirmed{color:#4caf50;}
.cq5lc7p{position:absolute;top:14px;right:14px;-webkit-flex-shrink:0;-ms-flex-negative:0;flex-shrink:0;height:14px;margin:-10px -12px -10px 0;color:#a3a5ba;background-color:transparent;border:0;outline:none;cursor:pointer;-webkit-transition:color 0.15s;transition:color 0.15s;-webkit-appearance:none;-moz-appearance:none;appearance:none;}.cq5lc7p:hover{color:#000;}
.cfx0rn4{width:14px;height:14px;}
.s1uejjom{-webkit-flex-shrink:0;-ms-flex-negative:0;flex-shrink:0;width:15px;height:15px;margin-right:8px;}
.sylh52d{position:relative;width:48px;height:48px;}.sylh52d > :nth-child(1){position:absolute;top:0;left:0;}.sylh52d > :nth-child(2){position:absolute;right:0;bottom:0;}
.b1rg3ij6{-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin-top:8px;padding-top:12px;}
