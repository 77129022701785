.w1hopukw{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;margin-top:32px;}
.sehlyl9{color:#161616;font-weight:500;font-size:16px;font-family:'Aktiv Grotesk Corp',sans-serif;line-height:24px;}
.cg7v8xu.sehlyl9{margin-bottom:8px;}
.ckelebc{margin-bottom:20px;color:#161616;font-size:14px;font-family:'Aktiv Grotesk Corp',sans-serif;line-height:18px;}

.s18gbf7y{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;margin:12px 0;}
.s7k0v70{-webkit-flex:1;-ms-flex:1;flex:1;height:3px;background:rgba(0,0,0,0.1);border-radius:2px;}.s7k0v70.red{background:#f43d3d;opacity:0.8;}.s7k0v70.orange{background:#ffa631;opacity:0.8;}.s7k0v70.green{background:#77db7c;opacity:0.8;}.s7k0v70.blue{background:#5887ff;}.s7k0v70:not(:last-child){margin-right:8px;}
.r1tmprh3{margin:0;padding-left:22px;}
.r1y5x9ps{color:#161616;font-size:14px;font-family:'Aktiv Grotesk Corp',sans-serif;line-height:18px;opacity:0.3;}.r1y5x9ps.done{-webkit-text-decoration:line-through;text-decoration:line-through;}.r1y5x9ps:not(:last-child){margin-bottom:12px;}
.rbzp9xf.sehlyl9{margin:20px 0 12px;}
.bljd3ip.bjxnci7{margin-top:32px;}
