
.t125v6ar{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;}
.f1kpvddt{color:#000;font-weight:600;font-size:16px;line-height:24px;}
.a1xutgog{color:#5887ff;cursor:pointer;}.a1xutgog.disabled{cursor:auto;pointer-events:none;}.a1xutgog.error{color:#f43d3d;}
.mbqjhps{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;margin-top:20px;}
.wyamx8w{width:24px;height:24px;color:#a3a5ba;}
.tmhxo83{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;width:44px;height:44px;background:#f6f6f8;border-radius:12px;}.tmhxo83.isOpen{background:#5887ff;}.tmhxo83.isOpen .wyamx8w{color:#fff;}
.il59nxx{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex:1;-ms-flex:1;flex:1;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;min-width:0;margin-left:20px;}
.s1fma3tf{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;height:44px;}
.t1p4oy70{max-width:200px;overflow:hidden;color:#000;font-weight:600;font-size:24px;line-height:140%;white-space:nowrap;text-overflow:ellipsis;}
.e155d16l{color:#a3a5ba;}
.c1cyshqi{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin-left:26px;}
.cjo6ugp{width:24px;height:24px;color:#000;}
.tcjr7eg{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;min-width:0;cursor:pointer;}.tcjr7eg.isOpen .t1p4oy70,.tcjr7eg.isOpen .cjo6ugp{color:#5887ff;}
.a1imv21i{max-width:200px;color:#000;font-weight:600;font-size:28px;line-height:120%;text-align:right;background:transparent;border:0;outline:none;-webkit-appearance:none;-moz-appearance:none;appearance:none;}.a1imv21i::-webkit-input-placeholder{color:#a3a5ba;}.a1imv21i::-moz-placeholder{color:#a3a5ba;}.a1imv21i:-ms-input-placeholder{color:#a3a5ba;}.a1imv21i::placeholder{color:#a3a5ba;}
.bz4tul9{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;margin-top:3px;color:#a3a5ba;font-weight:600;font-size:16px;line-height:24px;}
.b148rq82{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;}
.a1lb4bqw{margin-left:3px;}
.d2s5aw9{position:absolute;right:0;left:0;z-index:1;margin-top:8px;overflow:hidden;background:#fff;border-radius:12px;box-shadow:0 10px 32px rgba(0,0,0,0.1);}
.d1p24s80{padding:0 20px;border-radius:0 0 12px 12px;box-shadow:none;-webkit-backdrop-filter:blur(15px);backdrop-filter:blur(15px);}
.t14j33mn{padding:12px 0;color:#a3a5ba;font-weight:600;font-size:14px;line-height:140%;}
.f16fw80u{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex:1;-ms-flex:1;flex:1;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;height:66px;}.f16fw80u > :not(:last-child){margin-right:12px;}
.d9cgkt2{max-height:400px;padding-bottom:14px;overflow-y:auto;}
.t150noiw{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin:0 20px;color:#a3a5ba;font-weight:600;font-size:14px;line-height:140%;}
.y1ih9bl6.t150noiw{height:32px;}
.a1w2uucq.t150noiw{height:44px;}
