.w16igb2p{position:relative;}
.sbx7kcd{color:#161616;font-weight:500;font-size:16px;font-family:'Aktiv Grotesk Corp',sans-serif;line-height:24px;}
.su66ebt.sbx7kcd{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;margin-bottom:8px;}
.c1fu9rlt{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;width:24px;height:24px;background:#f6f6f8;border-radius:4px;}
.cetuax8{width:12px;height:12px;color:#161616;}
.qonw96l{width:20px;height:20px;color:#a3a5ba;}
.pithify{width:328px;}
.d15egks1.sbx7kcd{margin:24px 0 0;}
.a1bpmcri{margin:8px 0 22px;}
.t1791wbf{padding-right:0;padding-left:0;}
